import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Iubenda from "react-iubenda-policy"

import Nav from "./nav"
import Socials from "./socials"

import "./footer.scss"

const Footer = ({ theme, currentIndex }) => {
  const myPolicy = 58712151
  const year = new Date().getFullYear()

  return (
    <footer className={`${theme} section-${currentIndex}`}>
      <div className="Footer-meta">
        <Socials />
        <span className="Footer-legal">
          &copy; { year } TIV Inc. &bull; All Rights Reserved &bull;{" "}
          <a href="mailto:hello@tiv.co">Contact</a>
          {" "}&bull;{" "}
          <Iubenda id={myPolicy} type="privacy" styling="nostyle">
          Privacy
          </Iubenda>
          {" "}&bull;{" "}
          <Iubenda id={myPolicy} type="terms-and-conditions" styling="nostyle">
          Terms
          </Iubenda>
          {" "}&bull;{" "}
          <Iubenda id={myPolicy} type="cookie" styling="nostyle">
          Cookies
          </Iubenda>
          {" "}&bull;{" "}
          <a href="https://www.linkedin.com/company/tivinc/jobs/">👋 Join Our Team</a>
        </span>
      </div>
      <Nav currentIndex={currentIndex} />
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
