import { sample, clone, isEqual, random } from "lodash"
import React, { useState, useEffect, useRef } from "react"
import raf from "raf"
import * as THREE from "three"
import WAVES from "vanta/dist/vanta.waves.min"
import ReactFullpage from "@fullpage/react-fullpage"
import colorLerp from "color-lerp"
// import ViralLoops from 'viral-loops-react'

import Layout from "../components/layout"
import SectionHome from "../components/section-home"
import SectionCard from "../components/section-card"
import SectionTrophy from "../components/section-trophy"
import SectionKeycaps from "../components/section-keycaps"
import SectionHeadphones from "../components/section-headphones"
import SectionOutro from "../components/section-outro"
import Models from "../components/models"
import Header from "../components/header"
import Footer from "../components/footer"

const COLORS = {
  BLACK: "#070707",
  BRIGHT_ORANGE: "#F7941D",
  DARK_ORANGE: "#c33b10",
  BRIGHT_TEAL: "#007a7a",
  LIGHT_GRAY: "#a7a7a7",
  DARK_GRAY: "#606161",
  WHITE: "#FFFFFF",
}

const COLOR_STEPS = 100
let BACKGROUND

const IndexPage = () => {
  const [vantaEffect, setVantaEffect] = useState(0)
  const [colorArray, setColorArray] = useState([COLORS.BLACK])
  const [currentIndex, setIndex] = useState(0)
  const [currentModel, setModel] = useState("none")
  const [currentTheme, setTheme] = useState("light")
  const [currentSection, setSection] = useState("SectionHome")
  const [settings, updateSettings] = useState({
    color: colorArray[0],
    shininess: 73.0,
    waveHeight: 40,
    waveSpeed: 0.2,
    zoom: 1,
  })

  const backgroundRef = useRef(null)

  raf(function tick() {
    let prevSettings = clone(settings)

    // Color
    if (colorArray.length > 1) {
      colorArray.shift()
      settings.color = colorArray[0]
    }

    // Set new values but only if they've changed
    if (!isEqual(prevSettings, settings)) {
      if (BACKGROUND) {
        BACKGROUND.setOptions(settings)
      }
    }

    // Next frame
    raf(tick)
  })

  const updateEffect = (
    section = "SectionHome",
    color = COLORS.BLACK,
    zoom = 1,
    model,
    theme = "light"
  ) => {
    setSection(section)

    setColorArray(colorLerp(colorArray[0], color, COLOR_STEPS, "hex"))

    settings.zoom = zoom
    // settings.waveSpeed = random(2, 20)
    // settings.waveHeight = random(2, 40)

    if (model) {
      setModel(model)
    }
    
    setTheme(theme)
  }

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        (BACKGROUND = WAVES({
          el: backgroundRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: true,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: settings.color,
          shininess: settings.shininess,
          waveHeight: settings.waveHeight,
          waveSpeed: settings.waveSpeed,
          zoom: settings.zoom,
        }))
      )
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (
    <Layout>

      <Header theme={currentTheme} currentIndex={currentIndex} />
      <div
        ref={backgroundRef}
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <ReactFullpage
          licenseKey="05CF8EC7-EFBA4E32-99A0219F-4FBB3DB5"
          onLeave={(origin, destination, direction) => {
            setIndex(destination.index)
            updateEffect(
              destination.item.id,
              destination.item.attributes.color.nodeValue,
              destination.item.attributes.zoom.nodeValue,
              destination.item.attributes.model.nodeValue,
              destination.item.attributes.theme.nodeValue
            )
          }}
          scrollingSpeed={1000}
          keyboardScrolling={true}
          animateAnchor={true}
          recordHistory={true}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <SectionHome colors={COLORS} />
                <SectionCard colors={COLORS} />
                <SectionTrophy colors={COLORS} />
                <SectionKeycaps colors={COLORS} />
                <SectionHeadphones colors={COLORS} />
                <SectionOutro colors={COLORS} />
              </ReactFullpage.Wrapper>
            )
          }}
        />
        <Models model={currentModel} />
        <div className={`Section-gradients ${currentSection}`}>
          <div className={`Section-gradient bright-orange`} />
          <div className={`Section-gradient dark-orange`} />
          <div className={`Section-gradient bright-teal`} />
          <div className={`Section-gradient darkest-gray`} />
          <div className={`Section-gradient black`} />
          <div className={`Section-gradient white`} />
        </div>
      </div>
      <Footer theme={currentTheme} currentIndex={currentIndex} />
    </Layout>
  )
}

export default IndexPage
