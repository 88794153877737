import React from "react"
import Section from "./section"

import tiv from "../images/model-keycaps.png"

import "./section.scss"

const SectionKeycaps = ({ colors }) => (
  <Section
    name="SectionKeycaps"
    zoom={1.3}
    color={colors.LIGHT_GRAY}
    model="keycaps"
    theme="dark"
  >
    <div className="Layout-columns center">
      <div className="Layout-column three Section-text">
        <h2>CTRL+ALT+DEL FEES</h2>
        <p>
          No hidden fees, ever. We’ll always give you the TLDR if something is worth paying for.
        </p>
      </div>
      <div className="Layout-column one Section-model">
        {/* <img src={locomotive} alt="Tiv Keycaps" /> */}
      </div>
    </div>
  </Section>
)

export default SectionKeycaps
