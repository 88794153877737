import React from "react"
import Section from "./section"
import Logo from "./logo"

import "./section.scss"

const SectionHome = ({ colors }) => (
  <Section name="SectionHome" zoom={1} color={colors.BLACK} model="none">
    <div className="Layout-columns center">
      <div className="Layout-column four Section-text">
        <div className="move-up">
          <Logo />
          <h2>{`The Rewards Card\n Built For Gamers`}</h2>
          <p>Get early access</p>
          <div className="cta">
            <button
              className="button dark-orange prefinery-form-cta">
              Join the waitlist
            </button>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default SectionHome
