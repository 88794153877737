import React from "react"

const Instagram = () => (
  <svg
    width="46"
    height="34"
    viewBox="0 0 46 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7964 0.400024H45.9491L33.1527 33.5527H0L12.7964 0.400024Z"
      fill="black"
    />
    <path
      d="M23.3906 12.58C21.1406 12.58 19.3477 14.4081 19.3477 16.6229C19.3477 18.8729 21.1406 20.6659 23.3906 20.6659C25.6055 20.6659 27.4336 18.8729 27.4336 16.6229C27.4336 14.4081 25.6055 12.58 23.3906 12.58ZM23.3906 19.2596C21.9492 19.2596 20.7539 18.0995 20.7539 16.6229C20.7539 15.1815 21.9141 14.0214 23.3906 14.0214C24.832 14.0214 25.9922 15.1815 25.9922 16.6229C25.9922 18.0995 24.832 19.2596 23.3906 19.2596ZM28.5234 12.4393C28.5234 11.912 28.1016 11.4901 27.5742 11.4901C27.0469 11.4901 26.625 11.912 26.625 12.4393C26.625 12.9667 27.0469 13.3885 27.5742 13.3885C28.1016 13.3885 28.5234 12.9667 28.5234 12.4393ZM31.1953 13.3885C31.125 12.1229 30.8438 10.9979 29.9297 10.0839C29.0156 9.1698 27.8906 8.88855 26.625 8.81824C25.3242 8.74792 21.4219 8.74792 20.1211 8.81824C18.8555 8.88855 17.7656 9.1698 16.8164 10.0839C15.9023 10.9979 15.6211 12.1229 15.5508 13.3885C15.4805 14.6893 15.4805 18.5917 15.5508 19.8925C15.6211 21.1581 15.9023 22.2479 16.8164 23.1971C17.7656 24.1112 18.8555 24.3925 20.1211 24.4628C21.4219 24.5331 25.3242 24.5331 26.625 24.4628C27.8906 24.3925 29.0156 24.1112 29.9297 23.1971C30.8438 22.2479 31.125 21.1581 31.1953 19.8925C31.2656 18.5917 31.2656 14.6893 31.1953 13.3885ZM29.5078 21.2635C29.2617 21.9667 28.6992 22.494 28.0312 22.7753C26.9766 23.1971 24.5156 23.0917 23.3906 23.0917C22.2305 23.0917 19.7695 23.1971 18.75 22.7753C18.0469 22.494 17.5195 21.9667 17.2383 21.2635C16.8164 20.244 16.9219 17.7831 16.9219 16.6229C16.9219 15.4979 16.8164 13.037 17.2383 11.9823C17.5195 11.3143 18.0469 10.787 18.75 10.5057C19.7695 10.0839 22.2305 10.1893 23.3906 10.1893C24.5156 10.1893 26.9766 10.0839 28.0312 10.5057C28.6992 10.7518 29.2266 11.3143 29.5078 11.9823C29.9297 13.037 29.8242 15.4979 29.8242 16.6229C29.8242 17.7831 29.9297 20.244 29.5078 21.2635Z"
      fill="white"
    />
  </svg>
)

export default Instagram
