import React from "react"

const Nav = ({currentIndex}) => {
  const move = () => {
    if(currentIndex >= 5) {
      window.fullpage_api.moveTo(1)
    } else {
      window.fullpage_api.moveSectionDown()
    }
  }

  return (
    <div className="Nav" onClick={()=> move()}>
      <div className="Nav-index">
        {`0${currentIndex + 1} / 06`}
      </div>
      <div className={`Nav-arrow index-${currentIndex}`}>
        <svg fill="none" viewBox="0 0 67 67" xmlns="http://www.w3.org/2000/svg">
          <circle cx="33.5" cy="33.522" r="31.5" stroke="#fff" strokeWidth="3" />
          <path d="M53 33.1467L33.125 53.0217L13.25 33.1467H53Z" fill="#fff" />
          <rect x="26.398" y="15.106" width="12.842" height="29.354" fill="#fff" />
        </svg>
      </div>
    </div>
  )
}

export default Nav
