import React from "react"
import Section from "./section"

import tiv from "../images/model-headphones.png"

import "./section.scss"

const SectionHeadphones = ({ colors }) => (
  <Section
    name="SectionHeadphones"
    zoom={1.4}
    color={colors.BLACK}
    model="headphones"
  >
    <div className="Layout-columns center">
      <div className="Layout-column three Section-text">
        <h2>CUSTOM-BUILT REWARDS</h2>
        <p>
          Redeem points for cash, games, DLC, hardware and exclusive perks.
          Our rewards engine personalizes your experience, so you get more from your time and money.
        </p>
      </div>
      <div className="Layout-column one Section-model">
        {/* <img src={locomotive} alt="Tiv Headphones" /> */}
      </div>
    </div>
  </Section>
)

export default SectionHeadphones
