import React from "react"

import Twitter from "./twitter"
import Instagram from "./instagram"

const Socials = () => (
  <div className="Socials">
    <a href="https://www.twitter.com/tivcard" target="_blank">
      <Twitter />
    </a>

    <a href="https://www.instagram.com/tivcard" target="_blank">
      <Instagram />
    </a>
  </div>
)

export default Socials
