import React from "react"
import Section from "./section"

import tiv from "../images/model-trophy.png"

import "./section.scss"

const SectionTrophy = ({ colors }) => (
  <Section
    name="SectionTrophy"
    zoom={1.2}
    color={colors.BRIGHT_TEAL}
    model="trophy"
    theme="light"
  >
    <div className="Layout-columns center">
      <div className="Layout-column three Section-text">
        <h2>GAME MORE,<br/> EARN MORE</h2>
        <p>
          No spending category BS. Just more cash and better rewards from a card that truly fits your lifestyle. Spend your way and watch your gameplay amp up the points you earn.
        </p>
      </div>
      <div className="Layout-column one Section-model">
        {/* <img src={locomotive} alt="Tiv Trophy" /> */}
      </div>
    </div>
  </Section>
)

export default SectionTrophy
