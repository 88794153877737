import React from "react"
import Section from "./section"

import tiv from "../images/model-tiv-card.png"

import "./section.scss"

const SectionCard = ({ colors }) => (
  <Section
    name="SectionCard"
    zoom={1.1}
    color={colors.DARK_ORANGE}
    model="card"
  >
    <div className="Layout-columns center">
      <div className="Layout-column three Section-text">
        <h2>THE NEW CARD META</h2>
        <p>
          We decided to rewrite the rules and create the most epic rewards card imaginable.
          Tiv makes the time you spend gaming more valuable and more rewarding no matter how you spend your money.
        </p>
      </div>
      <div className="Layout-column one Section-model">
        {/* <img src={locomotive} alt="Tiv Card" /> */}
      </div>
    </div>
  </Section>
)

export default SectionCard
