import React from "react"

const LogoWhite = () => (
  <svg
    className="logo"
    viewBox="0 0 171 83"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
  >
    <path
      d="M0 3.43799H23.71V18.9667H15.5321L0 3.43799ZM66.4952 18.96H42.6512L27.1125 3.43799H50.9564L66.4952 18.96Z"
      fill="white"
      className="light-t"
    />
    <path
      d="M78.3501 0L93.8822 15.6638V82.9933H90.5802L75.0749 67.4645H78.3434V0H78.3501Z"
      fill="white"
      className="light-i"
    />
    <path
      d="M155.602 0L171 15.6638L145.113 82.9933H142.113L126.581 67.3294H129.581L155.602 0Z"
      fill="white"
      className="light-v"
    />
    <path
      d="M42.6513 18.8451L27.3738 3.43799H27.1126V67.3361L42.6513 82.9999V18.9667V18.8451Z"
      fill="#BCBEC0"
      className="dark-t"
    />
    <path
      d="M133.091 49.6055L128.322 61.9731L114.424 25.8362L113.199 22.3981L104.619 0L120.01 15.6638L133.091 49.6055Z"
      fill="#BCBEC0"
      className="dark-v"
    />
  </svg>
)

export default LogoWhite
