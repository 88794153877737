import React from "react"

const Twitter = () => (
  <svg
    width="46"
    height="34"
    viewBox="0 0 46 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7964 0.400024H45.9491L33.1527 33.5527H0L12.7964 0.400024Z"
      fill="black"
    />
    <path
      d="M31.0273 12.9667C31.7305 12.4393 32.3633 11.8065 32.8555 11.0682C32.2227 11.3495 31.4844 11.5604 30.7461 11.6307C31.5195 11.1737 32.082 10.4706 32.3633 9.59167C31.6602 10.0135 30.8516 10.33 30.043 10.5057C29.3398 9.76746 28.3906 9.34558 27.3359 9.34558C25.2969 9.34558 23.6445 10.9979 23.6445 13.037C23.6445 13.3182 23.6797 13.5995 23.75 13.8807C20.6914 13.705 17.9492 12.2284 16.1211 10.0135C15.8047 10.5409 15.6289 11.1737 15.6289 11.8768C15.6289 13.1425 16.2617 14.2675 17.2812 14.9354C16.6836 14.9003 16.0859 14.7596 15.5938 14.4784V14.5135C15.5938 16.3065 16.8594 17.7831 18.5469 18.1346C18.2656 18.205 17.9141 18.2753 17.5977 18.2753C17.3516 18.2753 17.1406 18.2401 16.8945 18.205C17.3516 19.6815 18.7227 20.7362 20.3398 20.7714C19.0742 21.7557 17.4922 22.3534 15.7695 22.3534C15.4531 22.3534 15.1719 22.3182 14.8906 22.2831C16.5078 23.3378 18.4414 23.9354 20.5508 23.9354C27.3359 23.9354 31.0273 18.3456 31.0273 13.4589C31.0273 13.2831 31.0273 13.1425 31.0273 12.9667Z"
      fill="white"
    />
  </svg>
)

export default Twitter
