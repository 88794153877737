import React from "react"
import Section from "./section"
import LogoWhite from "./logoWhite"

import "./section.scss"

const SectionOutro = ({ colors }) => (
  <Section
    name="SectionOutro"
    zoom={1.5}
    color={colors.DARK_ORANGE}
    model="none"
  >
    <div className="Layout-columns center">
      <div className="Layout-column four Section-text">
        <LogoWhite />
        <h2>GET EARLY ACCESS</h2>
        <p>
          Join us and be the first to earn exclusive rewards and perks through the upcoming Tiv Beta. Play a part in shaping the first rewards card that gives gamers more for doing what they love.
        </p>
        <div className="cta">
          <button
            className="button black prefinery-form-cta">
            Join the waitlist
          </button>
        </div>
      </div>
    </div>
  </Section>
)

export default SectionOutro
