import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

import trophy from "../images/model-trophy.png"
import headpones from "../images/model-headphones.png"
import keycapT from "../images/model-keycap-t.png"
import keycapI from "../images/model-keycap-i.png"
import keycapV from "../images/model-keycap-v.png"
import keycapF from "../images/model-keycap-foreground.png"
import card from '../images/model-tiv-card.png'

import "./models.scss"

const Models = ({model}) => (
  <div className="Models">
    <div className={classNames(`Model ModelCard`, {'active': (model === 'card')})}>
      <div className="float"><img src={card} alt="Tiv Card" /></div>
      <div className="shadow" />
    </div>
    <div className={classNames(`Model ModelTrophy`, {'active': (model === 'trophy')})}>
      <div className="float"><div className="rotate-right"><img src={trophy} alt="Tiv Trophy" /></div></div>
      <div className="shadow" />
    </div>
    <div className={classNames(`Model ModelKeycaps`, {'active': (model === 'keycaps')})}>
      <div className="Keycap Keycap-t">
        <div className="float"><img src={keycapT} alt="Tiv Keycap T" /></div>
        <div className="shadow" />
      </div>
      <div className="Keycap Keycap-i">
        <div className="float"><img src={keycapI} alt="Tiv Keycap I" /></div>
        <div className="shadow" />
      </div>
      <div className="Keycap Keycap-v">
        <div className="float"><img src={keycapV} alt="Tiv Keycap V" /></div>
        <div className="shadow" />
      </div>
      <div className="Keycap Keycap-f">
        <div className="float"><img src={keycapF} alt="Tiv Keycap Foreground" /></div>
        <div className="shadow" />
      </div>
    </div>
    <div className={classNames(`Model ModelHeadphones`, {'active': (model === 'headphones')})}>
      <div className="float"><img src={headpones} alt="Tiv Headphone" /></div>
      <div className="shadow" />
    </div>
  </div>
)

Models.propTypes = {
  name: PropTypes.string,
}

Models.defaultProps = {
  name: ``,
}

export default Models
