import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

import Logo from "../images/tiv-color.svg"
import Socials from "./socials"

import "./header.scss"

const Header = ({ theme, currentIndex }) => (
  <header className={`${theme} section-${currentIndex}`}>
    <h1 className="Header-brand" onClick={() => window.fullpage_api.moveTo(1)}>
      <img className="tiv-mark" src={Logo} alt="Tiv" />
    </h1>
    <div className="Header-cta prefinery-form-cta">
      <button
        className={classNames(
          "button",
          { black: theme === "light" },
          { white: theme === "dark" }
        )}>
        Join the waitlist
      </button>
      <Socials />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
