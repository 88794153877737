import PropTypes from "prop-types"
import React from "react"

const Section = ({name, color, zoom, model, theme = "light", children}) => (
  <section
    id={name}
    data-anchor={name}
    className={`section ${name}`}
    color={color}
    zoom={zoom}
    model={model}
    theme={theme}
  >
    {children}
  </section>
)

Section.propTypes = {
  name: PropTypes.string,
}

Section.defaultProps = {
  name: ``,
}

export default Section
